import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.route.firstChild;
          while (route?.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route?.outlet === 'primary')
      )
      .subscribe((route) => {
        route?.data.subscribe((page: any) => {
          let seo = this.extractSEO(page);
          this.updateSEO(seo?.title, seo?.description, seo?.image);
        });
      });
  }

  init() {}

  extractSEO(obj: any) {
    for (let key in obj) {
      if (obj[key] && obj[key].seo) {
        return obj[key].seo;
      }
    }
    return null;
  }

  setCanonical() {
    let link: HTMLLinkElement | null =
      this.document.querySelector('[rel=canonical]');
    if (!link) {
      const head = this.document.getElementsByTagName('head')[0];
      link = this.document.createElement('link');
      head.appendChild(link);
    }
    link.setAttribute('rel', 'canonical');
    link.setAttribute(
      'href',
      environment.siteUrl + this.router.url.split('?')[0]
    );
  }

  async updateSEO(title: string, description: string, image?: string) {
    description = description ?? '';
    const regex = /<\/?[^>]+(>|$)/g;
    description = description.replace(regex, '');
    this.setCanonical();
    this.title.setTitle(title + ' | ' + environment.siteName);
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({
      property: 'og:site_name',
      content: environment.siteName,
    });
    this.meta.addTag({
      property: 'og:url',
      content: environment.siteUrl + this.router.url.split('?')[0],
    });

    this.meta.addTag({
      property: 'og:title',
      content: environment.siteName + ' - ' + title,
    });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({
      property: 'og:image',
      content: image ? image : '',
    });
    this.meta.addTag({
      name: 'twitter:image',
      content: image ? image : '',
    });
    this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:description', content: description });
  }

  addStructuredData(renderer: Renderer2, nativeElement: any, data: any) {
    const script = renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(data);
    renderer.appendChild(nativeElement, script);
  }
}
